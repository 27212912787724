<!--    vue页面：ForgetPassword.vue     -->
<!--
    页面：paychyvideo的找回密码界面
    功能：用户由此找回密码
    更新日志：
    1/22/2020：v1.0
      release
    2/1/2020：v1.0.1
      1.修复了按下回车页面刷新的bug
      2.加入输入邮箱后按下回车自动提交的功能
    ★待解决问题：
      1.错误信息尚未进行分类
-->
<template>
  <div class="loginPic">
    <div v-loading="loading" class="w">
      <h1>
        <router-link to="/home">PatchyVideo</router-link>
      </h1>
      <div class="top in">
        <h3 style="color: #909399;">{{ $t("reset_psd") }}</h3>
      </div>

      <el-form ref="FormRef" :model="FormRef" class="middle in" :rules="rules">
        <el-form-item prop="email">
          <el-input
            id="email"
            v-model="FormRef.email"
            name="email"
            type="email"
            clearable
            :placeholder="$t('input_tip')"
            prefix-icon="el-icon-message"
            @keyup.enter.native="resetpass()"
          ></el-input>
        </el-form-item>
      </el-form>

      <!-- 登录按钮 -->
      <div class="bottom in">
        <div class="login in" @click="resetpass()">{{ $t("button") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    this.$i18n.locale = localStorage.getItem("lang");
    return {
      // 邮件地址
      FormRef: {
        email: "",
      },
      // 校验地址
      rules: {
        email: [
          { required: true, message: this.$t("email_tip"), trigger: "blur" },
          { type: "email", message: this.$t("err_tip"), trigger: ["blur"] },
        ],
      },
      // 视频列表是否属于加载状态的判断
      loading: false,
    };
  },
  created() {
    // 初始化页面名为 login
    this.$store.commit("changeBgc", "forgetPassword");
    // 修改网站标题
    document.title = this.$t("title") + " - PatchyVideo";
  },
  mounted() {
    console.log("背景图片p站ID：66686322，如有侵权请联系本站开发者（本站账号：admin）删除");
  },
  methods: {
    // 发送邮件
    resetpass() {
      // 先使页面出于加载状态
      this.loading = true;
      // 表单验证
      this.$refs.FormRef.validate((valid) => {
        if (valid) {
          this.axios({
            method: "post",
            url: "be/user/request_resetpass.do",
            data: {
              email: this.FormRef.email,
              lang: localStorage.getItem("lang"),
            },
          }).then((result) => {
            this.loading = false;
            if (result.data.status == "FAILED") {
              this.open();
            } else {
              this.open2();
              this.$router.push("/home");
            }
          });
        } else {
          // 加载结束，加载动画消失
          this.loading = false;
        }
      });
    },
    open() {
      this.$message({
        message: this.$t("verification_failed"),
        type: "error",
      });
    },
    open2() {
      this.$message({
        message: this.$t("post_success"),
        type: "success",
      });
    },
  },
};
</script>

<style scoped>
.loginPic {
  height: 100%;
  width: 100%;
  /* background: url("/static/img/forgetPassword.png") no-repeat top center; */
  /* background-position: 200px 0; */
  background-size: cover;
  background-attachment: fixed;
  background-color: #646257;
}
.alert {
  color: red;
}
input::-webkit-input-placeholder {
  color: #c8c8c8;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c8c8c8;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c8c8c8;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c8c8c8;
}

* {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
  color: #ff7792;
}

.w {
  position: relative;
  top: 100px;
  display: block;
  width: 300px;
  height: 320px;
  padding: 50px;
  background-color: white;
  box-shadow: 0px 0px 80px #ffeef1;
  background-position-x: 50%;
  left: 200px;
  margin: auto;
}
.w h1 {
  width: 100%;
  height: 60px;
  display: block;
  text-align: center;
}

.in {
  width: 300px;
}

.top {
  margin-bottom: 50px;
  text-align: center;
}
.top a {
  font-weight: 400;
  color: #969696;
}
.top a:hover {
  font-weight: 700;
  color: #ea6f5a;
  border-bottom: 2px solid #ff6685;
}
.top b {
  margin: 0px 10px 0px;
  font-size: 10px;
}

.middle input {
  padding-left: 10px;
  width: 100%;
  height: 50px;
  cursor: text;
  font-size: 14px;
  color: #646257;

  border: 1px solid #c8c8c8;
  background-color: hsla(0, 0%, 71%, 0.1);
}

.login {
  padding: 0px 5px;
  margin: 20px 0px 10px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  border-radius: 25px;
  color: #fff;
  background: #ff99ad;
}
.login:hover {
  cursor: pointer;
  background: #ff7792;
}

.forgetPassword {
  text-align: left;
  cursor: pointer;
}
.forgetPassword:hover {
  color: #409eff;
}

p {
  margin: 10px 0;
  padding: 0;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #969696;
}

.last {
  margin: auto;
  margin-top: 50px;
  height: 50px;
  width: 250px;

  border-top: 1px solid #c8c8c8;
}
.last p {
  display: block;
  margin: auto;
  width: 100px;
  padding: 10px;
  background-color: #fff;
  margin-top: -80px;
}

i {
  font-style: normal;
}

i:hover {
  cursor: pointer;
  color: blue;
}
.el-form-item {
  margin-top: 30px;
}
</style>

<i18nf></i18nf>
